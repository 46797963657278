<template>
  <section class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Cursos</a>
        </li>
      </ul>
    </nav>
    
    <button class="button m-b-md" @click="showModal = true">Adicionar</button>

    <b-table
      :data="data"
      :columns="columns"
      :paginated="true"
      per-page="10"
      current-page.sync="1"
      :pagination-simple="false"
      default-sort-direction="asc"
      default-sort="user.first_name"
      aria-next-label="Próxima pagina"
      aria-previous-label="Pagina anterior"
      aria-page-label="Page"
      aria-current-label="Pagina atual"
      detailed
      :opened-detailed="[1]"
      detail-key="_id"
      :show-detail-icon="true"
    >
      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <b-button class="m-r-sm" type="is-primary" @click="edit(props)">Editar</b-button>

              <router-link
                class="button is-link m-r-sm"
                :to="{name: 'Módulos', params:{ name: props.row._id, curName: props.row.name }}"
                :id="props.row.name"
              >Módulos</router-link>

              <b-button type="is-danger" @click="confirm(props)">Remover</b-button>
            </div>
          </div>
        </article>
      </template>
    </b-table>

    <Modal
      :inputs="inputs"
      :active="showModal"
      :clear="clear"
      @functionByModal="addCourse"
      @close="showModal = false"
    ></Modal>
    <Modal
      :inputs="ediInputs"
      :active="showModal2"
      :clear="clear"
      @functionByModal="update"
      @close="showModal2 = false"
    ></Modal>
  </section>
</template>

<script>
import Axios from "axios";

import Modal from "@/components/widgets/Modal";
export default {
  components: { Modal },
  data() {
    return {
      showModal: false,
      showModal2: false,
      clear: false,
      instructor: [],
      columns: [
        {
          field: "name",
          label: "Nome",
          sortable: true
        },
        {
          field: "updatedAt",
          label: "Última alteração",
          sortable: true
        }
      ],
      inputs: [
        { label: "Curso", type: "text", model: "name", required: true },
        {
          label: "Introdução",
          type: "textarea",
          model: "description",
          required: true
        },

        { label: "Horas", type: "text", model: "hourlyLoad" },
        {
          label: "Número de Modulos",
          type: "text",
          model: "modulesAmount"
        },
        {
          label: "Número de Exercicios",
          type: "text",
          model: "lessonsAmount"
        },
        {
          label: "Número de Vídeos",
          type: "text",
          model: "videosAmount"
        },
        {
          label: "Validade em meses",
          type: "text",
          model: "voucherTime"
        },
        { label: "Nivel", type: "text", model: "level" },
        {
          label: "Instrutor",
          type: "select",
          model: "instructor",
          content: this.instructor
        },
        {
          label: "URL do video(vimeo)",
          type: "text",
          model: "videoDescription"
        },
        { label: "Imagem", type: "file", model: "imageDescription" },
        { label: "Apostila", type: "file", model: "docs" },
        { label: "Preço", type: "text", model: "price" },
        { label: "Pré-requisitos", type: "textarea", model: "requirement" },
        { label: "Publico Alvo", type: "textarea", model: "targetAudience" },
        { label: "ATIVIDADE (Utilizado para emissão do certificado)", type: "text", model: "extra.atividade" },
        { label: "MODALIDADE (Utilizado para emissão do certificado)", type: "text", model: "extra.modalidade" },
        { label: "BASE LEGA (Utilizado para emissão do certificado)", type: "text", model: "extra.baseLegal" }

        // {label: "Curso", type: "text", model: "curso"},
      ],
      data: [],
      ediInputs: []
    };
  },
  methods: {
    edit(data) {
      if(!data.row.extra) {
        data.row.extra = {
          modalidade: null,
          baseLegal: null
        }
      }

      console.log(data.row)

      this.ediInputs = [
        {
          label: "Nome",
          type: "text",
          model: "name",
          name: data.row.name,
          _id: data.row._id,
          required: true
        },
        {
          label: "Descrição",
          type: "textarea",
          model: "description",
          description: data.row.description,
          required: true
        },
        {
          label: "Horas",
          type: "number",
          model: "hourlyLoad",
          hourlyLoad: data.row.hourlyLoad
        },
        {
          label: "Número de Módulos",
          type: "text",
          model: "modulesAmount",
          modulesAmount: data.row.modulesAmount
        },
        {
          label: "Número de Exercícios",
          type: "text",
          model: "lessonsAmount",
          lessonsAmount: data.row.lessonsAmount
        },
        {
          label: "Número de Vídeos",
          type: "text",
          model: "videosAmount",
          videosAmount: data.row.videosAmount
        },
        {
          label: "Validade em meses",
          type: "text",
          model: "voucherTime",
          voucherTime: data.row.voucherTime
        },
        {
          label: "Instrutor",
          type: "select",
          model: "instructor",
          content: this.instructor,
          instructor: data.row.instructor
        },
        { label: "Nivel", type: "text", model: "level", level: data.row.level },
        {
          label: "URL do video(vimeo)",
          type: "text",
          model: "videoDescription",
          videoDescription: data.row.videoDescription
        },
        {
          label: "Imagem",
          type: "file",
          model: "imageDescription",
          imageDescription: data.row.imageDescription
        },
        {
          label: "Apostila",
          type: "file",
          model: "docs",
          docs: data.row.docs
        },
        {
          label: "Preço",
          type: "text",
          model: "price",
          price: data.row.price
        },
        {
          label: "Pré-requisitos",
          type: "textarea",
          model: "requirement",
          requirement: data.row.requirement[0]
        },
        {
          label: "Publico Alvo",
          type: "textarea",
          model: "targetAudience",
          targetAudience: data.row.targetAudience
        },
        {
          label: "CRCP",
          type: "text",
          model: "crcpCode",
          crcpCode: data.row.crcpCode
        },
        {
          label: "ATIVIDADE (Utilizado para emissão do certificado)",
          type: "text",
          model: "extra.atividade",
          'extra.atividade': data.row.extra.atividade
        },
        {
          label: "MODALIDADE (Utilizado para emissão do certificado)",
          type: "text",
          model: "extra.modalidade",
          'extra.modalidade': data.row.extra.modalidade
        },
        {
          label: "BASE LEGA (Utilizado para emissão do certificado)",
          type: "text",
          model: "extra.baseLegal",
          'extra.baseLegal': data.row.extra.baseLegal
        }
      ];
      this.showModal2 = true;
    },
    update(data) {
      console.log(data)
      let dados = Object.assign({}, data);
      dados.price = parseFloat(dados.price);
      dados = this.toNumber(dados);
      Axios.put(`/v1/courses/${this.ediInputs[0]._id}`, dados)
        .then(data => {
          if (data.data) {
            this.getCourse();
            this.showModal2 = false;
            this.$buefy.notification.open({
              message: "Dados atualizados com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    remove(data) {
      Axios.delete(`/v1/courses/${data.row._id}`)
        .then(data => {
          if (data.data) {
            this.getCourse();
            this.$buefy.notification.open({
              message: "Curso excluido com sucesso!",
              type: "is-success"
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    addCourse(data) {
      let dados = Object.assign({}, data);
      dados = this.toNumber(dados);
      Axios.post(`/v1/courses/`, dados)
        .then(data => {
          if (data.data) {
            this.getCourse();
            this.showModal = false;
            this.$buefy.notification.open({
              message: "Curso criado com sucesso!",
              type: "is-success"
            });
            this.clear = !this.clear;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getCourse() {
      Axios.get(`/v1/courses/`)
        .then(data => {
          if (data.data) {
            this.data = data.data.data;
            data.data.data.map((item, index) => {
              this.data[index].updatedAt = new Date(
                item.updatedAt
              ).toLocaleDateString("pt-BR");
            });
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    getInstructor() {
      Axios.get(`/v1/users?roles=instructor&fields=name`)
        .then(data => {
          if (data.data) {
            this.instructor = data.data.data;
            this.inputs[8].content = data.data.data;
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    confirm(data) {
      this.$buefy.dialog.confirm({
        message: "Tem certeza que deseja <b>excluir</b> este curso?",
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.remove(data)
      });
    },
    toNumber(item) {
      let dados = item;
      dados.requirement ? dados.requirement = [dados.requirement] : '';
      dados.hourlyLoad = dados.hourlyLoad * 1;
      dados.price = dados.price * 1;
      dados.videosAmount = dados.videosAmount * 1;
      dados.modulesAmount = dados.modulesAmount * 1;
      dados.lessonsAmount = dados.lessonsAmount * 1;
      return dados;
    }
  },
  mounted() {
    this.getCourse();
    this.getInstructor();
  }
};
</script>

<style>
</style>
